export const mobileSize = 479;
export const bigMobileSize = 768;
export const tabletSize = 1024;
export const desktopSize = 1280;
export const bigDesktopSize = 1700;

const mediaQueries = {
  'Mobile': `@media screen and (max-width: ${mobileSize}px)`,
  'Landscape': `@media screen and  (orientation: landscape)`,
  'Portrait': `@media screen and  (orientation: portrait)`,
  'BigMobile': `@media screen and (min-width: ${mobileSize + 1}px) and (max-width: ${bigMobileSize}px)`,
  'AfterMobile': `@media screen and (min-width: ${mobileSize + 1}px)`,
  'Tablet': `@media screen and (min-width: ${mobileSize + 1}px) and (max-width: ${tabletSize}px)`,
  'AfterTablet': `@media screen and (min-width: ${tabletSize + 1}px)`,
  'Laptop': `@media screen and (min-width: ${tabletSize + 1}px) and (max-width: ${desktopSize - 1}px)`,
  'Desktop': `@media screen and (min-width: ${desktopSize}px) and (max-width: ${bigDesktopSize - 1}px)`,
  'BigDesktop': `@media screen and (min-width: ${bigDesktopSize}px)`
};

export default mediaQueries;