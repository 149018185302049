import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import spacing from "src/constants/spacing";
import mediaQueries from "src/constants/mediaQueries";
import { useApp } from "src/@context/App";
import { Link } from "react-router-dom";
import { Language, NextLanguage } from "src/utils/Languages";

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,

      backgroundColor: "#eee",

      [mediaQueries.Mobile]: {
        height: spacing.HeightHeader.Mobile,
      },
      [mediaQueries.Tablet]: {
        height: spacing.HeightHeader.Tablet,
      },
      [mediaQueries.Laptop]: {
        height: spacing.HeightHeader.Laptop,
      },
      [mediaQueries.Desktop]: {
        height: spacing.HeightHeader.Desktop,
      },
      [mediaQueries.BigDesktop]: {
        height: spacing.HeightHeader.BigDesktop,
      },
    },
    headeInner: {
      maxWidth: spacing.MaxWebsiteWidth,
      margin: "0 auto",
      padding: "0 40px",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "100%",
    },
    logo: {
      display: "flex",
      alignItems: "center",
    },
    svgContainer: {
      width: 40,
      height: 40,
      marginRight: 10,
    },
    svg: {
      width: "100%",
      height: "100%",
    },
    name: {
      fontSize: 21,
    },
    nav: {},
    menuLink: {
      color: "inherit",
      textDecoration: "none",
      display: "inline-block",
      marginLeft: 10,
    },
  })
);

const Header = (): JSX.Element => {
  const cls = useStyles();
  const { app } = useApp();
  const menus = app && app.Menus;

  return (
    <header className={cls.header}>
      <div className={cls.headeInner}>
        <div className={cls.logo}>
          <div className={cls.svgContainer}>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 28 24"
              enableBackground="new 0 0 28 24"
              className={cls.svg}
            >
              <polygon
                style={{ fill: "#FF9600" }}
                points="7.1,0 7.1,0 14,12 20.9,0 20.9,0 "
              />
              <polygon
                style={{ fill: "#FFFFFF" }}
                points="0.1,12 14,12 7.1,0 "
              />
              <polygon
                style={{ fill: "#FF0000" }}
                points="14,12 27.9,12 20.9,0 "
              />
              <path style={{ fill: "#E2E1E1" }} d="M14,12" />
              <rect x="0.1" style={{ fill: "none" }} width="27.7" height="24" />
              <polygon
                style={{ fill: "#0096FF" }}
                points="14,12 20.9,24 27.9,12 "
              />
              <polygon
                style={{ fill: "#009600" }}
                points="7.1,24 7.1,24 20.9,24 20.9,24 14,12 "
              />
              <polygon points="0.1,12 7.1,24 14,12 " />
            </svg>
          </div>
          <h2 className={cls.name}>Our Boilerplate</h2>
        </div>
        <nav>
          {menus &&
            menus.map((menu) => (
              <Link
                key={menu.UID}
                className={cls.menuLink}
                to={`/${Language}/${menu.UID}`}
              >
                {menu.Title}
              </Link>
            ))}
          <a
            className={cls.menuLink}
            aria-label="Switch lamguage"
            href={`?switch-lang=${NextLanguage}`}
          >
            {NextLanguage}
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
