import React, { useEffect, useCallback } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import * as ReactGA from "react-ga";
import DocumentMeta from "react-document-meta";
import useReactRouter from "use-react-router";
import { useApi } from "./@context/Api";
import { useApp } from "./@context/App";
import Home from "./Pages/Home";
import Menu from "./Pages/Menu";

const useStyles = makeStyles(() =>
  createStyles({
    loadingContainer: {
      transition: "opacity 0.2s",
      pointerEvents: "none",
      top: "50vh",
      left: "50vw",
      position: "fixed",
      transform: "translate(-50%,-50%)",
    },
    loadingText: {
      // ...stylesheet.BoldText,
    },
  })
);

const Pages = (): JSX.Element => {
  const cls = useStyles();

  const {
    location: { pathname },
  } = useReactRouter();
  const { get } = useApi();
  const { app } = useApp();
  const [page, setPage] = React.useState<IPageDto>();

  const [fetching, setFetching] = React.useState(false);

  // const scrollContainer = document.getElementById("scrollContainer");
  // React.useEffect(() => {
  //   if (!scrollContainer) {
  //     return;
  //   }

  //   if (scrollContainer.scrollTop > 0) {
  //     scrollContainer.scrollTop = 0;
  //   }
  // }, [page]);

  const fetchPage = useCallback(
    async (endpoint: string) => {
      setFetching(true);
      const pageDto = await get(endpoint);
      setPage(pageDto);
      setFetching(false);
      console.info(pageDto);
    },
    [get]
  );

  useEffect(() => {
    fetchPage(pathname);
  }, [fetchPage, get, pathname]);

  React.useEffect(() => {
    if (!app || !app.Config) {
      return;
    }
    const config = app.Config;

    if (!config.GoogleAnalyticsCode) {
      console.info("No GA Code");
      return;
    }

    ReactGA.initialize(`${config.GoogleAnalyticsCode}`);
    ReactGA.pageview(window.location.pathname);
  }, [page, app]);

  return (
    <>
      {page && app && (
        <DocumentMeta
          title={page.MetaTitle || app.Config.MetaTitle || ""}
          description={page.MetaDescription || app.Config.MetaDescription || ""}
        />
      )}
      <div
        className={cls.loadingContainer}
        style={{ opacity: (fetching && 1) || 0 }}
      >
        <div className={cls.loadingText}>LOADING</div>
      </div>

      {page && page.type === "Home" && <div>Home</div>}
      {page && page.type === "Menu" && <Menu {...(page as MenuDto)} />}

      {
        // <div ref={container}>
        //   {page && page.type === "Exhibitions" && (
        //     <Exhibitions {...(page as ExhibitionsDto)} />
        //   )}
        //   {page && page.type === "Fairs" && <Fairs {...(page as FairsDto)} />}
        //   {page && page.type === "Exhibition" && (
        //     <PanelPage {...(page as ExhibitionDto)} />
        //   )}
        //   {page && page.type === "Fair" && <PanelPage {...(page as FairDto)} />}
        //   {page && page.type === "Informations" && (
        //     <PanelPage {...(page as InformationsDto)} />
        //   )}
        //   {page && page.type === "Artists" && (
        //     <Artists {...(page as ArtistsDto)} />
        //   )}
        //   {page && page.type === "LegalInformation" && (
        //     <PanelPage {...(page as LegalInformationDto)} />
        //   )}
        //   {page && page.type === "Artist" && (
        //     <PanelPage {...(page as ArtistDto)} />
        //   )}
        //   {page && <Footer pageType={page.type} />}
        // </div>
      }
    </>
  );
};

export default Pages;
