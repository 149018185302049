export default {
  Gutter: {
    Mobile: 1,
    Tablet: 1,
    Laptop: 1,
    Desktop: 1,
    BigDesktop: 1,
  },
  SideMargins: {
    Mobile: 2,
    Tablet: 2,
    Laptop: 2,
    Desktop: 2,
    BigDesktop: 2,
  },
  HeightHeader: {
    Mobile: 80,
    Tablet: 80,
    Laptop: 80,
    Desktop: 80,
    BigDesktop: 80,
  },
  MaxWebsiteWidth: 1280,
};
