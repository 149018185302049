import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { App as AppContext } from "./@context/App";
import Header from "./components/Header";
import { Api } from "./@context/Api";
import mediaQueries from "./constants/mediaQueries";
import spacing from "./constants/spacing";
import Pages from "./Pages";

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "html, body": {
        position: "relative",
        width: "100%",
        height: "100%",
        "-webkit-font-smoothing": "antialiased!important",
        "-moz-osx-font-smoothing": "grayscale",
      },
    },
    app: {
      position: "relative",
      width: "100%",
      height: "100%",
    },
    content: {
      [mediaQueries.Mobile]: {
        paddingTop: spacing.HeightHeader.Mobile,
      },
      [mediaQueries.Tablet]: {
        paddingTop: spacing.HeightHeader.Tablet,
      },
      [mediaQueries.Laptop]: {
        paddingTop: spacing.HeightHeader.Laptop,
      },
      [mediaQueries.Desktop]: {
        paddingTop: spacing.HeightHeader.Desktop,
      },
      [mediaQueries.BigDesktop]: {
        paddingTop: spacing.HeightHeader.BigDesktop,
      },
    },
  })
);

const App = (): JSX.Element => {
  const cls = useStyles();
  const render = () => <Pages />;
  return (
    <BrowserRouter>
      <Api>
        <AppContext>
          <div className={cls.app}>
            <Header />
            <div className={cls.content}>
              <Switch>
                <Route render={render} />
              </Switch>
            </div>
          </div>
        </AppContext>
      </Api>
    </BrowserRouter>
  );
};

export default App;
